import {
    useStyles,
    useAppDisplayModeContext,
    Grid,
    pxToRem,
} from '@archipro-design/aria';

import * as S from '@modules/professional/component/page-layout/PageLayout.style';
import PageSections from '@modules/professional/component/page-layout/PageSections';

import { MainInformation } from '@modules/professional/component/overview-tab/main-info/MainInformation';
import { Downloads } from '@modules/professional/component/overview-tab/downloads/Downloads';

import type { SerializeFrom } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { convertLocationData } from '../util/contact-page-helper';
import type { LocationDataType } from '@modules/professional/type';

import type { APHandle } from '@modules/root';
import invariant from 'tiny-invariant';
import { useLocationsSortByDistance } from '~/modules/professional/hook/use-locations-sort-by-distance';
import { useIsDisabledInPreview } from '@modules/root/hook/use-is-disabled-in-preview';
import type { loader } from '~/modules/professional/page/loaders/overview-page-loader.server';
import { DetailsPageRelatedTileGrid } from '~/ui-rocco/pages/details-page/DetailsPageRelatedTileGrid';
import { PROFESSIONAL_SECTIONS_ANCHOR } from '../util/constants';

export type OverviewLoaderData = SerializeFrom<typeof loader>;
export type ProfessionalBannersData = OverviewLoaderData['banners'];

export const handle: APHandle = {
    showBannerAndLogoMobile: true,
    isOverviewPage: true,
    hideContactArchiPro: true,
};

const OverviewPage = () => {
    const { css } = useStyles();
    const { desktop } = useAppDisplayModeContext();
    const {
        professionalOverview,
        featuredProjects,
        featuredProducts,
        tilesAdminDataProducts,
        tilesAdminDataProjects,
        downloads,
    } = useLoaderData<typeof loader>();

    const disableSaveToDesignBoard =
        useIsDisabledInPreview('save_design_board');
    const disabledSocialLinks = useIsDisabledInPreview('profile_social_links');
    const disabledSendEnquiry = useIsDisabledInPreview('profile_send_enquiry');
    const disableDownloads = useIsDisabledInPreview('profile_downloads');

    const {
        ID: professionalId,
        EnquiryResponseRate: responseRate,
        LibraryLocations,
    } = useProfessionalInitialRouteData();

    const { sortedLocations, calculateDistance } =
        useLocationsSortByDistance<LocationDataType>({
            locations: LibraryLocations.map(convertLocationData),
            skip: true,
        });

    invariant(professionalId, 'Professional ID must be defined.');

    const hasFeaturedProjects =
        !professionalOverview?.IsManufacturer &&
        featuredProjects?.projectCount > 0;
    const hasFeaturedProducts =
        featuredProducts?.productCount > 0 &&
        professionalOverview?.IsManufacturer;

    return (
        <div className={css(S.PageContent)}>
            <div className={css(S.Container)}>
                <PageSections>
                    <Grid
                        columns={1}
                        className={css(
                            desktop
                                ? S.Sections
                                : {
                                      gridGap: pxToRem(60),
                                  }
                        )}
                    >
                        <MainInformation
                            locations={sortedLocations}
                            responseRate={responseRate}
                            calculateDistance={calculateDistance}
                            showProducts={
                                professionalOverview?.IsManufacturer &&
                                professionalOverview.ProductCount > 0
                            }
                            disabledSocialLinks={disabledSocialLinks}
                            disabledSendEnquiry={disabledSendEnquiry}
                            disableSaveToDesignBoard={disableSaveToDesignBoard}
                        />

                        <div className="px-4 md:px-0">
                            {hasFeaturedProducts && (
                                <DetailsPageRelatedTileGrid
                                    type="products"
                                    title={`Featured Products by ${professionalOverview.Title}`}
                                    tiles={
                                        featuredProducts.featuredProductsList.map(
                                            (product) => ({
                                                model: product,
                                                aspectRatio: 'square',
                                            })
                                        ) ?? []
                                    }
                                    cta={
                                        featuredProducts.productCount >
                                        (desktop ? 5 : 4)
                                            ? {
                                                  type: 'link',
                                                  to: `/professional/${professionalOverview.URLSegment}/our-products#${PROFESSIONAL_SECTIONS_ANCHOR}`,
                                                  label: 'View all',
                                              }
                                            : undefined
                                    }
                                    appDisplayMode={
                                        desktop ? 'desktop' : 'mobile'
                                    }
                                    columns={{
                                        desktop: 5,
                                        mobile: 2,
                                    }}
                                    displayCount={{
                                        desktop: 5,
                                        mobile: 4,
                                    }}
                                    tilesAdminData={tilesAdminDataProducts}
                                />
                            )}

                            {hasFeaturedProjects && (
                                <DetailsPageRelatedTileGrid
                                    type="projects"
                                    title={`Featured Projects by ${professionalOverview.Title}`}
                                    tiles={
                                        featuredProjects.featuredProjectsList.map(
                                            (project) => ({
                                                model: project,
                                                aspectRatio: 'default',
                                            })
                                        ) ?? []
                                    }
                                    cta={
                                        featuredProjects.projectCount >
                                        (desktop ? 3 : 2)
                                            ? {
                                                  type: 'link',
                                                  to: `/professional/${professionalOverview.URLSegment}/case-studies#${PROFESSIONAL_SECTIONS_ANCHOR}`,
                                                  label: 'View all',
                                              }
                                            : undefined
                                    }
                                    appDisplayMode={
                                        desktop ? 'desktop' : 'mobile'
                                    }
                                    columns={{
                                        desktop: 3,
                                        mobile: 1,
                                    }}
                                    displayCount={{
                                        desktop: 3,
                                        mobile: 2,
                                    }}
                                    tilesAdminData={tilesAdminDataProjects}
                                />
                            )}
                        </div>

                        {!disableDownloads && !!downloads.length && (
                            <Downloads
                                className="px-4 md:px-0"
                                professional={professionalOverview}
                                downloads={downloads}
                                showMoreLimit={4}
                            />
                        )}
                    </Grid>
                </PageSections>
            </div>
        </div>
    );
};
export default OverviewPage;
